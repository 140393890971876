body {
  background-color: blueviolet;
}
h1 {
  text-align: center;
}

.signature-pad {
  width: 100%;
  
}
.signature {
  width: 100%;
  height: 100%;
  border: 1px solid black;
}

.App {
  width: 60%;
  margin: auto;
  background-color: ghostwhite;
  padding: 20px;
}
